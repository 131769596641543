import classNames from 'classnames';
import React from 'react';
import Header from '../header/Header';
import Register from 'components/register/Register';
import AuthWrapper from '../auth/AuthWrapper';
import Receipt from 'components/receipt/Receipt';

const Layout = ({ children, className }) => {
  return (
    <div className={`${className}`}>
      <AuthWrapper>
        <div className="h-screen flex flex-col">
          <Header />
          <div className="flex flex-grow min-h-0">
            <div
              className={classNames(
                'flex-grow overflow-auto min-h-0',
                // , {
                //   'overflow-auto pr-2 h-[calc(100vh-var(--rf-header-height))]': cart.showCart,
                // }
              )}>
              {children}
            </div>
            <Receipt />
            <Register />

            {/* <div
            className={classNames(
              'h-[calc(100vh-var(--rf-header-height))] transition-width w-0 border-l-2 border-gray-2 px-0 overflow-hidden',
              { 'w-60 px-2': cart.showCart }
            )}
          >
            <Cart />
          </div> */}
          </div>
        </div>
      </AuthWrapper>
    </div>
  );
};

export default Layout;
