import api from 'api';
import { TERMINAL_STATUS, ECOM_STATUS, PAYMENT_STATUS } from 'enums';
import produce from 'immer';
import { calculateOrder } from 'lib/ecomHelper';

export const initalPosState = {
  payment: {
    showStatus: false,
    showPaymentConfirmation: false,
    status: 'none',
    message: '',
    event: '',
    serviceId: '',
  },
  activeOrder: null,
  activeReceipt: null,
};

export const posActions = (set, get) => ({

  closePaymentStatus: () => {
    set(
      produce(state => {
        state.pos.payment.showPaymentConfirmation = false;
        state.pos.payment.status = 'none';
        state.pos.payment.message = '';
      }),
    );
  },
  updatePaymentStatus: async statusMessage => {
    if (TERMINAL_STATUS.TENDER_FINAL.key === statusMessage.event) {
      set(
        produce(state => {
          state.pos.activeOrder = null;
          state.pos.payment.showStatus = statusMessage.paymentStatus === PAYMENT_STATUS.ONGOING.key;
          state.pos.payment.showPaymentConfirmation = true;
          state.pos.payment.status = statusMessage.paymentStatus;
          state.pos.payment.event = statusMessage.event;
          // state.pos.cart.statue = statusMessage.paymentStatus !== "ONGOING" ? "none" : state.cart.status;
        }),
      );
    } else {
      set(
        produce(state => {
          state.pos.payment.showStatus =
            statusMessage.paymentStatus === PAYMENT_STATUS.FAILURE.key ||
            statusMessage.paymentStatus === PAYMENT_STATUS.ONGOING.key;
          state.pos.payment.status = statusMessage.paymentStatus;
          state.pos.payment.event = statusMessage.event;
          // state.pos.cart.statue = statusMessage.paymentStatus !== "ONGOING" ? "none" : state.cart.status;
        }),
      );
    }
  },
  updatePosPaymentMessage: message => {
    set(
      produce(state => {
        state.payment.message = message;
      }),
    );
  },
  getActiveOrder: async orderGuid => {
    const result = await api.getOrder(orderGuid);
    if (!result.isAxiosError) {
      const { data } = result;
      set(
        produce(state => {
          state.pos.activeOrder = calculateOrder(data.payload);
          state.pos.activeReceipt = null;
        }),
      );
    } else {
      set(
        produce(state => {
          state.pos.activeOrder = null;
          state.pos.activeReceipt = null;
        }),
      );
    }
  },
  setActiveOrder: async order => {
    if (order === null) {
      set(
        produce(state => {
          state.pos.activeReceipt = null;
          state.pos.activeOrder = null;
        }),
      );
    } else {
      set(
        produce(state => {
          state.pos.activeReceipt = null;
          state.pos.activeOrder = calculateOrder(order);
        }),
      );
    }
  },
  getActiveReceipt: async orderGuid => {
    const result = await api.getOrder(orderGuid);
    if (!result.isAxiosError) {
      const { data } = result;
      set(
        produce(state => {
          state.pos.activeOrder = null;
          state.pos.activeReceipt = calculateOrder(data.payload);
        }),
      );
    } else {
      set(
        produce(state => {
          state.pos.activeReceipt = null;
          state.pos.activeOrder = null;
        }),
      );
    }
  },
  setActiveReceipt: async order => {
    if (order === null) {
      set(
        produce(state => {
          state.pos.activeOrder = null;
          state.pos.activeReceipt = null;
        }),
      );
    } else {
      set(
        produce(state => {
          state.pos.activeOrder = null;
          state.pos.activeReceipt = calculateOrder(order);
        }),
      );
    }
  },
  initiatePayment: async orderId => {
    const result = await api.initiatePayment(orderId);
    if (!result.isAxiosError) {
      const { payload } = result.data;
      set(
        produce(state => {
          state.pos.payment.showStatus = true;
          state.pos.payment.serviceId = payload.serviceId;
        }),
      );
      // store.setState((state) => {
      //   state.payment.status = payload.paymentStatus;
      //   state.payment.message = payload.message;
      // });
    }
  },
  markActiveOrderAsPicked: async orderGuid => {
    await api.setOrderStatus(orderGuid, {
      statusType: ECOM_STATUS.PICKED.statusType,
      statusState: ECOM_STATUS.PICKED.statusState,
    });
    const result = await api.getOrder(orderGuid);
    if (!result.isAxiosError) {
      const { data } = result;
      set(
        produce(state => {
          state.pos.activeOrder = calculateOrder(data.payload);
        }),
      );
    }
  },
  markActiveOrderAsUnPicked: async orderGuid => {
    await api.setOrderStatus(orderGuid, {
      statusType: ECOM_STATUS.AWAITING_PICKING.statusType,
      statusState: ECOM_STATUS.AWAITING_PICKING.statusState,
    });
    const result = await api.getOrder(orderGuid);
    if (!result.isAxiosError) {
      const { data } = result;
      set(
        produce(state => {
          state.pos.activeOrder = calculateOrder(data.payload);
        }),
      );
    }
  },
  markActiveOrderAsDelivered: async orderGuid => {
    await api.setOrderAsDelivered(orderGuid);
    const result = await api.getOrder(orderGuid);
    if (!result.isAxiosError) {
      const { data } = result;
      set(
        produce(state => {
          state.pos.activeOrder = calculateOrder(data.payload);
        }),
      );
    }
  },
  reversePayment: async (orderGuid, fromReceipt) => {
    await api.reversePayment(orderGuid);
    const result = await api.getOrder(orderGuid);
    if (!result.isAxiosError) {
      const { data } = result;
      if (fromReceipt) {
        set(
          produce(state => {
            state.pos.activeOrder = calculateOrder(data.payload);
          }),
        );
      } else {
        set(
          produce(state => {
            state.pos.activeOrder = calculateOrder(data.payload);
          }),
        );
      }

    }
  },
  cancelPayment: async serviceId => {
    const result = await api.cancelPayment(serviceId);
    if (!result.isAxiosError) {
      set(
        produce(state => {
          state.pos.payment.serviceId = '';
          state.pos.activeOrder = null;
        }),
      );
    }
  },
});
// import useBearStore from '@newStore/cart';
// import shallow from 'zustand/shallow';

// const [status, actions] = useBearStore(
//   (state) => [state.status, state.actions],
//   shallow
// );
