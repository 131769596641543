import { ECOM_STATUS } from 'enums';

const calculateGrandTotal = order => {
  return {
    incVat: order.grandTotalIncVat,
    exVat: order.grandTotalExVat,
  };
};

const calculateHandlingStatus = status => {
  return {
    state: status?.statusState,
  };
};

const calculatePaymentStatus = status => {
  return {
    state: status?.statusState,
    transactionId: status?.transactionId,
    internalIdentification: status?.internalIdentification,
  };
};

const mapCartArticles = apiCartItems => {
  if (typeof apiCartItems === 'undefined') return [];
  return apiCartItems.map(newOrderArticle => {
    return {
      sku: newOrderArticle.sku,
      name: newOrderArticle.name,
      quantity: newOrderArticle.quantity,
      priceUnit: {
        incVat: newOrderArticle.unitPriceIncVat,
        exVat: newOrderArticle.unitPriceExVat,
      },
      vat: {
        amount: newOrderArticle.vatAmount,
        percent: newOrderArticle.vatPercent,
      },
      priceTotal: {
        incVat: newOrderArticle.rowTotalIncVat,
        exVat: newOrderArticle.rowTotalExVat,
      },
    };
  });
};

const mapAddress = address => {
  return {
    email: address.email1,
    name: address.name,
    phone: address.phone1,
  };
};

export const calculateOrder = data => {
  console.log(data);
  return {
    id: data.guid,
    orderNumber: data.orderNumber,
    currency: typeof data.currency === 'undefined' ? 'GBP' : data.currency,
    grandTotal: calculateGrandTotal(data),
    address: mapAddress(data.orderAddress),
    articles: mapCartArticles(data.orderItems),
    handlingStatus: calculateHandlingStatus(data.handlingStatus),
    paymentStatus: calculatePaymentStatus(data.paymentStatus),
    pickupDate: data.pickupDate,
    sloopNumber: data.sloopNumber,
    regNumber: data.regNumber,
  };
};

export const isPayed = order => {
  return (
    order?.paymentStatus?.state === ECOM_STATUS.RESERVED.statusState ||
    order?.paymentStatus?.state === ECOM_STATUS.CAPTUREDCREATED.statusState ||
    order?.paymentStatus?.state === ECOM_STATUS.CAPTURED.statusState
  );
};

export const isRefunded = state => {
  return (
    state === ECOM_STATUS.REVERSALCREATED.statusState ||
    state === ECOM_STATUS.REFUNDED.statusState ||
    state === ECOM_STATUS.CANCELED.statusState
  );
};
