import React from 'react';
import useGlobalStore from 'store';

const Logout = ({ className }) => {
  const authActions = useGlobalStore(state => state.authActions);
  const { signOut } = authActions;

  const handleSignOut = () => {
    signOut();
    window.location.reload();
    console.log('signed out');
  };
  return (
    <button className={className} onClick={() => handleSignOut()}>
      <i className="fa-solid fa-arrow-right-from-bracket mr-1 "></i>
      Log out
    </button>
  );
};

export default Logout;
