import React, { useState, createRef } from 'react';
import styles from './SearchOrder.module.scss';
import classNames from 'classnames';
import { ArrowRightCircleIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import api from 'api';
import useGlobalStore from 'store';

const SearchOrder = ({ numberOfInputs = 3, className }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const posActions = useGlobalStore(state => state.posActions);
  const numerOfInputs = numberOfInputs;
  const [inputRefsArray] = useState(() => Array.from({ length: numerOfInputs }, () => createRef()));
  // const [currentIndex, setCurrentIndex] = useState(null);

  const [letters, setLetters] = useState(() => Array.from({ length: numerOfInputs }, () => ''));

  const searchOrder = async () => {
    //letters
    setShowError(false);
    setIsLoading(true);
    const result = await api.getOrder(`${letters[0]}${letters[1]}-${letters[2]}`);
    if (!result.isAxiosError) {
      const { payload } = result.data;
      setIsLoading(false);
      posActions.setActiveOrder(payload);
    } else {
      setIsLoading(false);
      setShowError(true);
      posActions.setActiveOrder(null);
    }
  };
  return (
    <div className="flex flex-col">
      <div className={classNames(styles.SearchOrder, 'grid gap-2 grid-flow-col auto-cols-max')}>
        <form action=""></form>
        <input
          ref={inputRefsArray[0]}
          className="text-lato text-[85px] text-center w-9 h-15 bg-white-1 border-1 border border-black-1/5 drop-shadow-sm rounded-lg bg-white shadow-inner ring-1 ring-black-1/5"
          type="text"
          id={`box${0}`}
          onChange={e => {
            const { value } = e.target;
            setLetters(letters =>
              letters.map((letter, letterIndex) => (letterIndex === 0 ? value : letter)),
            );
            if (value) {
              const nextInput = inputRefsArray?.[1]?.current;
              nextInput?.focus();
              nextInput?.select();
            }
          }}
          onClick={e => {
            e.target.select();
          }}
          value={letters[0]}
          max={'1'}
        />
        <input
          ref={inputRefsArray[1]}
          className="text-lato text-[85px] text-center w-9 h-15 bg-white-1 border-1 border border-black-1/5 drop-shadow-sm rounded-lg bg-white shadow-inner ring-1 ring-black-1/5"
          type="text"
          id={`box${1}`}
          onChange={e => {
            const { value } = e.target;
            setLetters(letters =>
              letters.map((letter, letterIndex) => (letterIndex === 1 ? value : letter)),
            );
            if (value) {
              const nextInput = inputRefsArray?.[2]?.current;
              nextInput?.focus();
              nextInput?.select();
            }
          }}
          onClick={e => {
            e.target.select();
          }}
          value={letters[1]}
          max={'1'}
        />
        <span className="font-bold text-5xl flex items-center text-gray-3">-</span>
        <input
          onKeyUp={e => {
            if (e.key === 'Enter') searchOrder();
          }}
          ref={inputRefsArray[2]}
          className="text-lato text-[85px] pl-1 w-50 h-15 bg-white-1 border-1 border border-black-1/5 drop-shadow-sm rounded-lg bg-white shadow-inner ring-1 ring-black-1/5"
          type="number"
          id={`box${2}`}
          onChange={e => {
            const { value } = e.target;
            setLetters(letters =>
              letters.map((letter, letterIndex) => (letterIndex === 2 ? value : letter)),
            );
          }}
          onClick={e => {
            e.target.select();
          }}
          value={letters[2]}
        />
        <button onClick={() => searchOrder()}>
          {isLoading ? (
            <ArrowPathIcon className="w-8 animate-spin" />
          ) : (
            <ArrowRightCircleIcon className="w-8" />
          )}
        </button>
      </div>
      <div className="flex items-center justify-center mt-2">
        {showError && (
          <span className="font-josefin text-red-1 text-3xl text-center">No order found</span>
        )}
      </div>
    </div>
  );
};

export default SearchOrder;
