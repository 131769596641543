import React from 'react';
import Container from '../components/container/Container';
import SearchOrder from '../components/searchOrder/SearchOrder';
import { Link } from 'react-router-dom';

const HomePage = props => {
  return (
    <Container>
      <div className="grid gap-2 grid-flow-col auto-cols-max">
        <Link to="/orders">
          <div className="flex flex-col items-center cursor-pointer align-center p-3 w-20 h-15 rounded bg-white-1 border-1 drop-shadow-md hover:drop-shadow-lg">
            <h3 className="font-josefin text-2xl">Orders</h3>
            <div className="mt-1">
              <i className="text-3xl fa-solid fa-bars-staggered"></i>
            </div>
          </div>
        </Link>

        <Link to="/receipts">
          <div className="flex flex-col items-center cursor-pointer align-center p-3 w-20 h-15 rounded bg-white-1 border-1 drop-shadow-md hover:drop-shadow-lg">
            <h3 className="font-josefin text-3xl">Receipts</h3>
            <div className="mt-1">
              <i className="text-3xl fa-solid fa-receipt"></i>
            </div>
          </div>
        </Link>
      </div>

      <div className="pt-3 flex justify-center mt-10">
        <div>
          <h2 className="font-josefin text-4xl mb-2">Find Order</h2>
          <SearchOrder className="" />
        </div>
      </div>
    </Container>
  );
};

export default HomePage;
