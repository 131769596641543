import React, { useEffect, useState } from 'react';
import { TERMINAL_STATUS } from 'enums';
import TerminalStatus from 'components/terminalStatus/TerminalStatus';
import * as signalR from '@microsoft/signalr';
import useGlobalStore from 'store';
import PaymentStatus from 'components/paymentStatus/PaymentStatus';

const ServerMessageProvider = ({ className }) => {
  const [connection, setConnection] = useState(null);
  const showStatus = useGlobalStore(state => state.pos.payment.showStatus);
  const showPaymentConfirmation = useGlobalStore(
    state => state.pos.payment.showPaymentConfirmation,
  );
  const event = useGlobalStore(state => state.pos.payment.event);
  const status = useGlobalStore(state => state.pos.payment.status);
  const { updatePaymentStatus } = useGlobalStore(state => state.posActions);

  useEffect(() => {
    console.log('Connecting');
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_BASE_URL}/posMessageHub`, { withCredentials: false })
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      console.log('Connected');
      connection.start().catch(err => console.log(err));
      connection.on('PosDisplayMessage', message => {
        console.log(message);
        updatePaymentStatus(JSON.parse(message));
      });
    }

    return () => {
      if (connection) {
        console.log('closing down');
        connection.stop().catch(err => console.log(err));
        connection.off('PosDisplayMessage');
      }
    };
  }, [connection, updatePaymentStatus]);

  return (
    <>
      <TerminalStatus
        open={showStatus}
        status={status}
        text={TERMINAL_STATUS[event]?.text}></TerminalStatus>
      <PaymentStatus
        open={showPaymentConfirmation}
        status={status}
        text={TERMINAL_STATUS[event]?.text}></PaymentStatus>
    </>
  );
};

export default ServerMessageProvider;
