import React from 'react';
import Container from '../components/container/Container';
import OrderList from '../components/orderList/OrderList';
import { fetcher } from 'api';
import useSWR from 'swr';
import { calculateOrder } from 'lib/ecomHelper';
import { ECOM_STATUS } from 'enums';

const ReceiptsPage = props => {
  const { data, error } = useSWR(
    process.env.REACT_APP_API_BASE_URL +
      `/v1/orders?states=${ECOM_STATUS.CAPTURED.statusState},${ECOM_STATUS.CAPTUREDCREATED.statusState},${ECOM_STATUS.RESERVED.statusState},${ECOM_STATUS.REVERSALCREATED.statusState},${ECOM_STATUS.REFUNDED.statusState},${ECOM_STATUS.CANCELED.statusState}`,
    fetcher,
  );

  if (error) return <div>failed to load</div>;

  const orderList = data?.payload.map(order => calculateOrder(order));

  return (
    <Container>
      <h1 className="font-josefin text-4xl mb-2">Receipts</h1>
      {/* <h1 className="font-josefin text-2xl mb-2">Not handed out to customer</h1> */}
      <OrderList isLoading={!data} orders={orderList} />

      {/* <h1 className="font-josefin text-4xl mb-2">Receipts</h1>
      <h1 className="font-josefin text-2xl mb-2">Payed and handed out</h1>
      <OrderListCards isLoading={!data} orders={orderList} /> */}
    </Container>
  );
};

export default ReceiptsPage;
