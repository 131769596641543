import api from 'api';
import produce from 'immer';

export const initalAuthState = {
  user: null,
  status: 'none',
  loading: false,
};

export const authActions = (set, get) => ({
  getCurrentUser: async () => {
    if (!get().auth.loading) {
      set(
        produce(state => {
          state.auth.loading = true;
        }),
      );
      const result = await api.getUser();
      if (!result.isAxiosError) {
        const { data } = result;
        set(
          produce(state => {
            state.auth.status = 'in';
            state.auth.user = data.payload;
            state.auth.loading = false;
          }),
        );
      } else {
        set(
          produce(state => {
            state.auth.status = 'out';
            state.auth.user = null;
            state.auth.loading = false;
          }),
        );
      }
    }
  },
  signIn: async formData => {
    set(
      produce(state => {
        state.auth.loading = true;
      }),
    );

    const result = await api.signIn(
      // store.state.market.id,
      formData.username,
      formData.password,
    );

    if (result.isAxiosError) {
      const { response } = result;
      console.log(response);
      set(
        produce(state => {
          state.auth.status = 'out';
          state.auth.errorMessage = 'Login Failed';
          state.auth.loading = false;
        }),
      );
    } else {
      const { data } = result;
      set(
        produce(state => {
          state.auth.status = data.userIsAnonymous ? 'out' : 'in';
          state.auth.user = data;
          state.auth.loading = false;
        }),
      );
    }
  },
  signOut: async marketId => {
    await api.signOut(marketId);
    set(
      produce(state => {
        state.auth.status = 'out';
        state.auth.user = null;
        state.assortment.prices.list = null;
        state.assortment.prices.showCustom = false;
      }),
    );
  },
});

// const const initalPosState = {
//   payment: {
//     showStatus: false,
//     status: "none",
//     message: "",
//     event: ""
//   },
//   cart: {
//     status: 'none'
//   }
// }
// export default create((set, get) => ({
//   auth: { ...initalAuthState },
//   pos: { ...initalPosState },
//   actions: {
//     auth: {

//     },
//     pos: {
//       updatePaymentStatus: (statusMessage) => {
//         set(
//           produce((state) => {
//             state.payment.showStatus = statusMessage.paymentStatus === "ONGOING";
//             state.payment.status = statusMessage.paymentStatus;
//             state.payment.event = statusMessage.event;
//             state.cart.statue = statusMessage.paymentStatus !== "ONGOING" ? "none" : state.cart.status;
//           })
//         );
//       },
//       updatePosPaymentMessage: (message) => {
//         set(
//           produce((state) => {
//             state.payment.message = message;
//           })
//         );
//       }
//     }

//   },
// }));
// import useBearStore from '@newStore/cart';
// import shallow from 'zustand/shallow';

// const [status, actions] = useBearStore(
//   (state) => [state.status, state.actions],
//   shallow
// );
