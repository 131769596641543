import React, { useEffect, useRef, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import classNames from 'classnames';
import logo from './../../assets/logo.png';

import { useGlobalVariable } from '../../hooks/useGlobalVariable';
import { useScreenSize } from '../../hooks/useScreenSize';
import List from '../list/List';
import styles from './Header.module.scss';

import DefaultRoutes from '../../routes/Default';
import Logout from 'components/auth/Logout';

const Header = () => {
  const { pathname } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const { isMobile } = useScreenSize();
  const [hasHamburger, setHasHamburger] = useState(true);
  const headerRef = useRef();
  const { setValue } = useGlobalVariable({
    key: '--rf-header-height',
    value: '0px',
    element: document.body,
  });

  const toggleMenu = () => setIsOpen(!isOpen);

  useEffect(() => {
    const scrollHandler = () => {
      let isScrolled = window.scrollY <= 5 ? false : true;
      setIsScrolled(isScrolled);
    };

    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  useEffect(() => {
    setHasHamburger(isMobile);
  }, [isMobile]);

  useEffect(() => {
    if (!headerRef.current) {
      return;
    }

    setValue(`${headerRef.current.clientHeight}px`);
  }, [headerRef, setValue]);

  const menuItemsMap = DefaultRoutes.sort((a, b) => {
    if (a.index < b.index) return -1;
    if (a.index > b.index) return 1;
    return 0;
  }).map(route => {
    if (!route.showInMenu) return null;
    return {
      text: route.text,
      href: route.href,
      as: route.as,
      isSelected: pathname === route.href,
      icon: route.icon,
    };
  });

  return (
    <>
      {/* Since the nav bar is fixed we want to occupy the same space to compensate for it */}
      <nav
        className={classNames('transition-colors top-0 w-full z-20 font-bold print:hidden', {
          'bg-transparent': isScrolled && !hasHamburger,
          'flex justify-between p-1 bg-black-1': !hasHamburger,
        })}
        ref={hasHamburger ? null : headerRef}>
        {hasHamburger ? (
          <>
            <div
              className={classNames(
                'transition-colors bg-black-1 p-1 flex justify-between items-center ',
                {
                  'bg-transparent': !isScrolled || isOpen,
                  'shadow-lg pb-1': isOpen,
                },
              )}
              ref={hasHamburger ? headerRef : null}>
              <Link to="/">
                <img className="h-[52px] w-auto" height="52" width="160" src={logo} alt="Logo" />
              </Link>
              <button
                type="button"
                className={classNames(
                  'py-0.5 px-1 rounded-lg text-2xl shrink-0 hover:bg-black-1 text-white-1',
                  {
                    'bg-gradient-to-r from-brand-1 to-red-1': isOpen,
                  },
                )}
                onClick={toggleMenu}>
                <i className="fa-solid fa-bars"></i>
              </button>
            </div>
            <div
              className={classNames('mx-1 -mt-1 rounded-lg shadow-lg overflow-hidden', {
                hidden: !isOpen,
              })}>
              <List items={menuItemsMap} />
            </div>
          </>
        ) : (
          <>
            <Link to="/">
              <img className="h-[52px] w-auto" height="52" width="160" src={logo} alt="Logo" />
            </Link>
            <ul className="flex items-center m-0 uppercase">
              {menuItemsMap.map(item => {
                if (item === null) return null;

                return (
                  <li key={item.text} className={classNames(styles.ListItem, 'm-1')}>
                    <Link to={item.href} className={'p-2 py-1 rounded text-white-1'}>
                      <span
                        className={classNames(styles.DecoratedText, {
                          [styles.isSelected]: item.isSelected,
                        })}>
                        {item.icon && <span className="mr-1">{item.icon}</span>}
                        {item.text}
                      </span>
                    </Link>
                  </li>
                );
              })}
              <li className={classNames(styles.ListItem, 'm-1')}>
                <Link className={'p-2 py-1 rounded text-white-1 '}>
                  <Logout className={`${styles.DecoratedText} uppercase`} />
                </Link>
              </li>
            </ul>
          </>
        )}
      </nav>
    </>
  );
};

export default Header;
