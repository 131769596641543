export const CART_TYPES = {
  CART: 1,
  WISH_LIST: 2,
};

export const LIST_VIEW = {
  LIST: 'row',
  GRID: 'card',
};

//Add market types for redirect
export const MARKET_TYPES = {
  SE: 'sv-se',
  NO: 'nb-no',
};

export const MARKET_REDIRECTS = {
  'sv-se': 'sv-se',
  'nb-no': 'nb-no',
};

export const CURRENCY_CODES = {
  'sv-se': 'SEK',
  'nb-no': 'NOK',
  'da-dk': 'DKK',
  en: 'EUR',
};

export const COUNTRY = {
  'sv-se': { long: 'Sverige', short: 'se' },
  'nb-no': { long: 'Norge', short: 'no' },
  'da-dk': { long: 'Danmark', short: 'dk' },
};

export const SEARCH_TYPE = {
  ASSORTMENT: 1,
  CONTENT: 2,
};

export const PAYMENT_STATUS = {
  SUCCESS: {
    key: 'SUCCESS',
    text: 'Payment success',
  },
  FAILURE: {
    key: 'FAILURE',
    text: 'Payment faliure',
  },
  ONGOING: {
    key: 'ONGOING',
    text: 'Ongoing',
  },
};
export const TERMINAL_STATUS = {
  TENDER_CREATED: {
    key: 'TENDER_CREATED',
    text: 'The tender has started',
  },
  CARD_INSERTED: {
    key: 'CARD_INSERTED',
    text: 'Card inserted',
  },
  CARD_SWIPED: {
    key: 'CARD_SWIPED',
    text: 'Card swiped',
  },
  WAIT_FOR_APP_SELECTION: {
    key: 'AWAITING_PIN',
    text: 'Waiting for payment application',
  },
  APPLICATION_SELECTED: {
    key: 'APPLICATION_SELECTED',
    text: 'Application choosen',
  },
  ASK_SIGNATURE: {
    key: 'ASK_SIGNATURE',
    text: 'Waiting for signature',
  },
  CHECK_SIGNATURE: {
    key: 'CHECK_SIGNATURE',
    text: 'Check customer signature',
  },
  SIGNATURE_CHECKED: {
    key: 'SIGNATURE_CHECKED',
    text: 'Signature ok',
  },
  WAIT_FOR_PIN: {
    key: 'WAIT_FOR_PIN',
    text: 'Waiting for pin',
  },
  PIN_DIGIT_ENTERED: {
    key: 'PIN_DIGIT_ENTERED',
    text: 'waiting for pin',
  },
  PIN_ENTERED: {
    key: 'PIN_ENTERED',
    text: 'Pin entered',
  },
  PRINT_RECEIPT: {
    key: 'PRINT_RECEIPT',
    text: 'Printing receipt',
  },
  RECEIPT_PRINTED: {
    key: 'RECEIPT_PRINTED',
    text: 'Receipt printed',
  },
  CARD_REMOVED: {
    key: 'CARD_REMOVED',
    text: 'Card removed',
  },
  TENDER_FINAL: {
    key: 'TENDER_FINAL',
    text: 'Tender final',
  },
  ASK_DCC: {
    key: 'ASK_DCC',
    text: 'Waiting for currency choice',
  },
  DCC_ACCEPTED: {
    key: 'DCC_ACCEPTED',
    text: 'Currency choosen',
  },
  DCC_REJECTED: {
    key: 'DCC_REJECTED',
    text: 'Currency choosen',
  },
  AWAITING_PIN: {
    key: 'AWAITING_PIN',
    text: 'Waiting for pin',
  },
};

export const ORDER_STATUS = {
  PAYED: 'Payed',
  PACKED: 'Packed',
  PAYMENT_FAILED: 'PaymentFailed',
  NOT_PAYED: 'ToBePayed',
};

export const ECOM_STATUS = {
  AWAITING_PICKING: {
    statusType: 'Handling',
    statusState: 'AwaitingPicking',
  },
  PICKED: {
    statusType: 'Handling',
    statusState: 'Picked',
  },
  DELIVERED: {
    statusType: 'Handling',
    statusState: 'Delivered',
  },
  CREATED: {
    statusType: 'Payment',
    statusState: 'Created',
  },
  RESERVED: {
    statusType: 'Payment',
    statusState: 'Reserved',
  },
  CAPTUREDCREATED: {
    statusType: 'Payment',
    statusState: 'CaptureCreated',
  },
  CAPTURED: {
    statusType: 'Payment',
    statusState: 'Captured',
  },
  REFUNDED: {
    statusType: 'Payment',
    statusState: 'Refunded',
  },
  CANCELED: {
    statusType: 'Payment',
    statusState: 'Cancelled',
  },
  INSTORE: {
    statusType: 'Payment',
    statusState: 'InStore',
  },
  REVERSALCREATED: {
    statusType: 'Payment',
    statusState: 'ReversalCreated',
  },
  AUTHORIZATIONFAILED: {
    statusType: 'Payment',
    statusState: 'AuthorizationFailed',
  }

};
