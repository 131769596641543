import { useEffect, useState } from 'react';

export const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const setScreenSizeStates = () => {
      const screenSizeMap = {
        mobile: 640,
        tablet: 1024,
      };

      const viewportSize = window.innerWidth;

      if (viewportSize < screenSizeMap.mobile) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }

      if (
        viewportSize >= screenSizeMap.mobile &&
        viewportSize < screenSizeMap.tablet
      ) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }

      if (viewportSize >= screenSizeMap.tablet) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };

    window.addEventListener('resize', setScreenSizeStates);
    setScreenSizeStates();

    return () => window.removeEventListener('resize', setScreenSizeStates);
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
