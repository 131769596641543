import React from 'react';
import classNames from 'classnames';
import OrderStatus from 'components/orderStatus/OrderStatus';
import { isRefunded } from 'lib/ecomHelper';
import useGlobalStore from 'store';

const OrderList = ({ orders, isLoading }) => {
  const posActions = useGlobalStore(state => state.posActions);

  //if (orders === null || typeof orders === 'undefined' || orders.length < 1) return null;

  if (isLoading) {
    return (
      <div className="OrderList flex  flex-col opacity-30">
        <div
          className={classNames(
            'font-bold flex flex-wrap w-full border border-gray-2 px-2 py-2 bg-white-1 drop-shadow-md mb-2  h-7',
          )}>
          <div className="flex w-full">
            <div className="flex flex-grow items-center mr-4" data-toggle="collapse"></div>
          </div>
        </div>
        <div
          className={classNames(
            'font-bold flex flex-wrap w-full border border-gray-2 px-2 py-2 bg-white-1 drop-shadow-md mb-2 h-7',
          )}>
          <div className="flex w-full">
            <div className="flex flex-grow items-center mr-4" data-toggle="collapse"></div>
          </div>
        </div>
        <div
          className={classNames(
            'font-bold flex flex-wrap w-full border border-gray-2 px-2 py-2 bg-white-1 drop-shadow-md mb-2  h-7',
          )}>
          <div className="flex w-full">
            <div className="flex flex-grow items-center mr-4" data-toggle="collapse"></div>
          </div>
        </div>
      </div>
    );
  }

  // return (
  //   <div className="OrderList flex  flex-col">
  //     <div
  //       className={classNames(
  //         'font-bold flex flex-wrap w-full border border-gray-2 px-2 py-2 bg-white-1 drop-shadow-md mb-2 cursor-pointer',
  //         {
  //           'bg-red-1/30': isRefunded('order.paymentStatus.state'),
  //         },
  //       )}>
  //       <div className="flex w-full">
  //         <div className="flex flex-grow items-center mr-4" data-toggle="collapse">
  //           <div className="flex items-center">
  //             <h4 className="ml-2 font-josefin text-xl w-10">{'cy-1234'}</h4>
  //             <span className="ml-2 w-6">
  //               <OrderStatus state={'Delivered'} />
  //             </span>
  //             <span className="ml-2 w-6">
  //               <OrderStatus state={'Reserved'} />
  //             </span>
  //           </div>

  //           <h4 className="ml-auto ml-2 font-josefin text-xl">
  //             {new Intl.NumberFormat('en', {
  //               style: 'currency',
  //               currency: 'BPB',
  //             }).format('123')}
  //           </h4>
  //         </div>
  //       </div>
  //     </div>
  //     <div
  //       className={classNames(
  //         'font-bold flex flex-wrap w-full border border-gray-2 px-2 py-2 bg-white-1 drop-shadow-md mb-2 cursor-pointer',
  //         {
  //           'bg-red-1/30': isRefunded('ReversalCreated'),
  //         },
  //       )}>
  //       <div className="flex w-full">
  //         <div className="flex flex-grow items-center mr-4" data-toggle="collapse">
  //           <div className="flex items-center">
  //             <h4 className="ml-2 w-10">{'cy-1234'}</h4>
  //             <span className="ml-2 w-6">
  //               <OrderStatus state={'Delivered'} />
  //             </span>
  //             <span className="ml-2 w-6">
  //               <OrderStatus state={'ReversalCreated'} />
  //             </span>
  //           </div>

  //           <h4 className="ml-auto">
  //             {new Intl.NumberFormat('en', {
  //               style: 'currency',
  //               currency: 'BPB',
  //             }).format('123')}
  //           </h4>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <div className="OrderList flex  flex-col">
      {orders
        .filter(
          order =>
            // showPayed
            //   ? order.paymentStatus.status === ECOM_STATUS.CAPTURED
            //   : order.paymentStatus.status !== ECOM_STATUS.CAPTURED,
            true,
        )
        .map((order, index) => {
          // debugger;
          return (
            <div
              className={classNames(
                'font-bold flex flex-wrap w-full border border-gray-2 px-2 py-2 bg-white-1 drop-shadow-md mb-2 cursor-pointer',
                {
                  'bg-red-1/30': isRefunded(order.paymentStatus.state),
                },
              )}
              key={index}>
              <div className="flex w-full">
                <div
                  className="flex flex-grow items-center mr-4"
                  onClick={() => posActions.getActiveReceipt(order.id)}
                  data-toggle="collapse">
                  <div className="flex items-center">
                    <h4 className="ml-2 font-josefin text-xl w-10">{order.orderNumber}</h4>
                    <span className="ml-2 w-6">
                      <OrderStatus state={order.handlingStatus.state} />
                    </span>
                    <span className="ml-2 w-6">
                      <OrderStatus state={order.paymentStatus.state} />
                    </span>
                  </div>
                  <h4 className="ml-auto">
                    {new Intl.NumberFormat('en', {
                      style: 'currency',
                      currency: order.currency,
                    }).format(order.grandTotal.incVat)}
                  </h4>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default OrderList;
