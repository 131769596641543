import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import './style/fonts.css';
import './style/tailwind.css';

import Layout from './components/layout/Layout';
import DefaultRoutes from './routes/Default';



function App() {
  return (
    <Router>
      <Routes>
        {DefaultRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.href}
              element={<Layout>{route.component}</Layout>}
            ></Route>
          );
        })}
      </Routes>
    </Router>
  );
}

export default App;
