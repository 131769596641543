import React, { useState } from 'react';
import useGlobalStore from 'store';
import OrderStatus from 'components/orderStatus/OrderStatus';
import { ECOM_STATUS } from 'enums';
import {
  PaperAirplaneIcon,
  ClipboardDocumentCheckIcon,
  CreditCardIcon,
  ReceiptRefundIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { isPayed, isRefunded } from 'lib/ecomHelper';
import cx from 'classnames';
import ConfirmationDialog from 'components/confirmationDialog/ConfirmationDialog';

const Receipt = ({ className = '' }) => {
  const posActions = useGlobalStore(state => state.posActions);
  const activeReceipt = useGlobalStore(state => state.pos.activeReceipt);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);

  const toggleRefundConfirmation = open => {
    setRefundDialogOpen(open);
  };

  const refundOrder = serviceId => {
    setRefundDialogOpen(false);
    posActions.reversePayment(activeReceipt.id, true);
  };

  if (activeReceipt === null) return null;
  return (
    <div className="relative">
      <div
        className={`absolute bg-white-1 right-0 drop-shadow-md w-60 flex flex-col h-full ${className}`}>
        <button
          onClick={() => posActions.setActiveReceipt(null)}
          className="top-2 px-2 py-1 flex items-center">
          <XMarkIcon className="w-3" />
        </button>
        <div className="h-full overflow-auto">
          <h2 className="px-2 font-josefin text-4xl my-2">Receipt</h2>
          <h3 className="px-2 font-josefin text-2xl">Order {activeReceipt.orderNumber}</h3>
          <div className="px-2 flex mt-1 gap-1 mb-1">
            <OrderStatus state={activeReceipt.handlingStatus.state} />
            {activeReceipt.paymentStatus?.state && (
              <OrderStatus state={activeReceipt.paymentStatus.state} />
            )}
          </div>
          <div className="px-2 flex flex-wrap">
            <div className="w-1/2 basis-1/2 mb-1">
              <h5 className="font-josefin font-bold">Order number</h5>
              <div>{activeReceipt.orderNumber}</div>
            </div>
            <div className="w-1/2 basis-1/2 mb-1">
              <h5 className="font-josefin font-bold">Pickup date</h5>
              <div>{`${
                activeReceipt.pickupDate ? new Date(activeReceipt.pickupDate).toDateString() : '-'
              }`}</div>
            </div>
            <div className="w-1/2 basis-1/2 mb-1">
              <h5 className="font-josefin font-bold">Pickup Time</h5>
              <div>{`${
                activeReceipt.pickupDate
                  ? new Date(activeReceipt.pickupDate).toLocaleTimeString('en-GB', {
                      hour: '2-digit',
                      minute: '2-digit',
                    })
                  : '-'
              }`}</div>
            </div>
            <div className="w-1/2 basis-1/2 mb-1">
              <h5 className="font-josefin font-bold">Name</h5>
              <div>{activeReceipt.address.name}</div>
            </div>

            <div className="w-1/2 basis-1/2 mb-1">
              <h5 className="font-josefin font-bold">Phone</h5>
              <div>{`${activeReceipt.address.phone ? activeReceipt.address.phone : '-'}`}</div>
            </div>
            <div className="w-full basis-full mb-1">
              <h5 className="font-josefin w-1/2 font-bold">Email</h5>
              <div>{`${activeReceipt.address.email ? activeReceipt.address.email : '-'}`}</div>
            </div>
            <div className="w-1/2 basis-1/2 mb-1">
              <h5 className="font-josefin w-1/2 font-bold">Booking reference</h5>
              <div>{`${activeReceipt.sloopNumber ? activeReceipt.sloopNumber : '-'}`}</div>
            </div>
            <div className="w-1/2 basis-1/2 mb-1">
              <h5 className="font-josefin w-1/2 font-bold">Reg Number</h5>
              <div>{`${activeReceipt.regNumber ? activeReceipt.regNumber : '-'}`}</div>
            </div>

            {isPayed(activeReceipt) && (
              <div className="w-full basis-full mb-1">
                <h5 className="font-josefin w-1/2 font-bold">Payment</h5>
                <div className="flex flex-col">
                  <span>PSP: {activeReceipt.paymentStatus.transactionId}</span>
                  <span>Ref no: {activeReceipt.paymentStatus.internalIdentification}</span>
                </div>
              </div>
            )}
          </div>
          <h3 className="px-2 mb-1 font-josefin text-2xl">Articles</h3>
          <div className="px-2 ">
            <div className="flex flex-col">
              {activeReceipt.articles.map((article, key) => {
                return (
                  <div key={key} className="py-1 border-b border-black-1">
                    <div>
                      {article.quantity} x {article.name}
                    </div>
                    <div className="flex justify-between">
                      <span className="italic ">
                        (
                        {new Intl.NumberFormat('en', {
                          style: 'currency',
                          currency: activeReceipt.currency,
                        }).format(article.priceUnit.incVat)}
                        )
                      </span>
                      <span className="font-bold text-lg">
                        {new Intl.NumberFormat('en', {
                          style: 'currency',
                          currency: activeReceipt.currency,
                        }).format(article.priceTotal.incVat)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="h-fit px-2 py-2 border-t border-gray-2 bg-white-1 drop-shadow-md">
          <div className="font-bold text-xl flex justify-between mb-2">
            <span>Grand total:</span>
            <span>
              {new Intl.NumberFormat('en', {
                style: 'currency',
                currency: activeReceipt.currency,
              }).format(activeReceipt.grandTotal.incVat)}
            </span>
          </div>
          {!(
            activeReceipt.handlingStatus.state === ECOM_STATUS.DELIVERED.statusState ||
            isRefunded(activeReceipt.paymentStatus.state)
          ) ? (
            <div>
              <button
                disabled={
                  isPayed(activeReceipt) &&
                  activeReceipt.handlingStatus.state === ECOM_STATUS.DELIVERED.statusState
                    ? 'true'
                    : ''
                }
                onClick={() =>
                  activeReceipt.handlingStatus.state === ECOM_STATUS.PICKED.statusState
                    ? posActions.markactiveReceiptAsUnPicked(activeReceipt.id)
                    : posActions.markactiveReceiptAsPicked(activeReceipt.id)
                }
                className={`w-full px-2 py-1 flex items-center font-josefin font-semibold text-lg border-2 rounded-md shadow-sm hover:scale-105 ease-out duration-300 ${
                  isPayed(activeReceipt) &&
                  activeReceipt.handlingStatus.state === ECOM_STATUS.DELIVERED.statusState
                    ? 'border-gray-2 bg-white-1 text-gray-2'
                    : 'border-black-1 bg-white-1 text-black'
                } ${
                  activeReceipt.handlingStatus.state === ECOM_STATUS.PICKED.statusState
                    ? 'awaiting-picking'
                    : 'picked'
                }`}>
                <ClipboardDocumentCheckIcon className="w-3 mr-2" />{' '}
                {activeReceipt.handlingStatus.state === ECOM_STATUS.PICKED.statusState
                  ? 'Mark as unpacked'
                  : 'Mark as packed'}
              </button>
              <button
                disabled={
                  isPayed(activeReceipt) &&
                  activeReceipt.handlingStatus.state !== ECOM_STATUS.DELIVERED.statusState
                    ? ''
                    : 'true'
                }
                onClick={() => posActions.markactiveReceiptAsDelivered(activeReceipt.id)}
                className={`w-full px-2 py-1 mt-1 flex items-center font-josefin font-semibold text-lg border-2 rounded-md shadow-sm hover:scale-105 ease-out duration-300 ${
                  isPayed(activeReceipt) &&
                  activeReceipt.handlingStatus.state !== ECOM_STATUS.DELIVERED.statusState
                    ? 'border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black'
                    : 'border-gray-2 bg-white-1 text-gray-2'
                }`}>
                <PaperAirplaneIcon className="w-3 mr-2" /> Hand out pre-payed order
              </button>

              {isPayed(activeReceipt) ? (
                <button
                  onClick={() => toggleRefundConfirmation(true)}
                  className="w-full px-2 py-2 mt-1 flex items-center font-josefin font-semibold text-lg border-2 rounded-md shadow-sm hover:scale-105 ease-out duration-300 border-red-1 bg-white-1 hover:bg-red-1 hover:text-white-1 text-red-1">
                  <ReceiptRefundIcon className="w-3 mr-2" /> Refund
                </button>
              ) : (
                <button
                  disabled={isPayed(activeReceipt) ? 'true' : ''}
                  onClick={() => posActions.initiatePayment(activeReceipt.id)}
                  className={`w-full px-2 py-2 mt-1 flex items-center font-josefin font-semibold text-lg border-2 rounded-md shadow-sm hover:scale-105 ease-out duration-300 ${
                    isPayed(activeReceipt)
                      ? 'border-gray-2 bg-white-1 text-gray-2'
                      : 'border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black'
                  }`}>
                  <CreditCardIcon className="w-3 mr-2" /> Card
                </button>
              )}
            </div>
          ) : (
            <div
              className={cx(' top-0 left-0 ', {
                payed: isPayed(activeReceipt),
                refunded: isRefunded(activeReceipt.paymentStatus.state),
              })}>
              <div className="px-4 ">
                {isRefunded(activeReceipt.paymentStatus.state) && (
                  <div className="flex items-center justify-center font-josefin font-semibold text-4xl">
                    <ReceiptRefundIcon className="w-15 mr-2 text-black-1" /> Refunded
                  </div>
                )}

                {isPayed(activeReceipt) &&
                  activeReceipt.handlingStatus.state === ECOM_STATUS.DELIVERED.statusState && (
                    <button
                      onClick={() => toggleRefundConfirmation(true)}
                      className="w-full px-2 py-2 my-4 flex items-center font-josefin font-semibold text-lg border-2 rounded-md  border-red-1 bg-white-1 hover:bg-red-1 hover:text-white-1 text-red-1">
                      <ReceiptRefundIcon className="w-3" /> Refund
                    </button>
                  )}
              </div>
            </div>
          )}
        </div>

        <ConfirmationDialog
          open={refundDialogOpen}
          header={'Confirm refund'}
          text={
            <div>
              <p className="mb-2">
                Customer will receive receipt on email {activeReceipt.address.email}
              </p>
              <p>
                If purchase is refunded the amount will the transferred back to the customer. If no
                payment exists, transaction will be cancelled.
              </p>
            </div>
          }
          okText={'Yes, refund order'}
          confirmCallback={() => refundOrder(activeReceipt.id)}
          cancelCallback={() => toggleRefundConfirmation(false)}
          cancelText={'No, close'}
        />
      </div>
    </div>
  );
};

export default Receipt;
