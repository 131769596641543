import { useEffect, useState } from 'react';

export const useGlobalVariable = ({ key, value: defaultValue, element }) => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (!element || typeof value === 'undefined') {
      return;
    }

    element.style.setProperty(key, value);

    return () => {
      element.style.removeProperty(key);
    };
  }, [value, key, element]);

  return {
    setValue,
  };
};
