import { initalAuthState, authActions } from './useAuthStore';
// import useMarketStore from './useMarketStore';
// import useOrderStore from './useOrderStore';
import { initalPosState, posActions } from './usePosStore';
// export { useGlobalStore };

import { create } from 'zustand';

export default create((set, get) => ({
  auth: { ...initalAuthState },
  authActions: authActions(set, get),
  pos: { ...initalPosState },
  posActions: posActions(set, get)
}));

