import React, { useEffect, useState } from 'react';
import Container from '../components/container/Container';
import OrderListCards from '../components/orderList/OrderListCards';
import useGlobalStore from 'store';
import { fetcher } from 'api';
import useSWR from 'swr';
import { calculateOrder } from 'lib/ecomHelper';
import { ArchiveBoxXMarkIcon, GiftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

const FILTERS = {
  ALL: {
    key: 'ALL',
    value: ['awaitingpicking', 'picked'],
  },
  AWAIT_PICKING: {
    key: 'AWAIT_PICKING',
    value: ['awaitingpicking'],
  },
  PACKED: {
    key: 'PACKED',
    value: ['picked'],
  },
};
const OrdersPage = props => {
  const activeOrder = useGlobalStore(state => state.pos.activeOrder);
  const today = new Date();
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  const [selectedDay, setSelectedDay] = useState(today);
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);

  const toggleFilter = filter => {
    const newArray = [...selectedFilters];
    var index = newArray.indexOf(filter.value);

    if (index === -1) {
      newArray.push(filter.value);
    } else {
      newArray.splice(index, 1);
    }
    setSelectedFilters(newArray);
  };
  // const [orderList, setOrderList] = useState(null);

  // const getOrders = async () => {
  //   const result = await api.getOrders();
  //   if (!result.isAxiosError) {
  //     setOrderList(result.data.payload);
  //   }
  // };
  // useEffect(() => {
  //   getOrders();
  // }, []);
  const { data, error, mutate } = useSWR(
    process.env.REACT_APP_API_BASE_URL +
      `/v1/orders?states=${
        selectedFilters.length === 0 ? FILTERS.ALL.value.join(',') : selectedFilters.join(',')
      }&pickupdate=${selectedDay.toISOString().split('T')[0]}&pickupTime=${selectedTime}`,
    fetcher,
  );

  useEffect(() => {
    const revalidateData = async () => {
      try {
        await mutate();
      } catch (error) {
        console.error('Error revalidating data:', error);
      }
    };

    revalidateData();
  }, [activeOrder?.handlingStatus?.state, mutate]);

  if (error) return <div>failed to load</div>;

  const orderList = data?.payload.map(order => calculateOrder(order));
  return (
    <Container>
      <h1 className="font-josefin text-4xl mb-2">
        {today.getDate() === selectedDay.getDate() ? 'Todays Orders ' : 'Tomorrows Orders'}
      </h1>

      <div className="flex flex-col mb-4">
        <div className="flex mb-2 ">
          <div className="flex flex-col mr-3">
            <h5 className="font-josefin font-bold text-sm">Dates</h5>
            <div className="flex gap-1">
              <button
                className={`flex h-7 w-[160px] flex-col items-center border border-black-1 rounded p-0.5 px-2 font-bold ${
                  today.getDate() === selectedDay.getDate()
                    ? 'bg-black-1 text-white-1 cursor-default'
                    : 'cursor-pointer'
                }`}
                onClick={() => setSelectedDay(today)}>
                <span className="font-josefin font-bold mb-0.5">Today</span>
                <span className="font-josefin font-bold text-xs"> ({today.toDateString()})</span>
              </button>
              <button
                className={`flex h-7 w-[160px] flex-col items-center border border-black-1 rounded p-0.5 px-2 font-bold ${
                  tomorrow.getDate() === selectedDay.getDate()
                    ? 'bg-black-1 text-white-1 cursor-default'
                    : 'cursor-pointer'
                }`}
                onClick={() => setSelectedDay(tomorrow)}>
                <span className="font-josefin font-bold mb-0.5">Tomorrow</span>

                <span className="font-josefin font-bold text-xs">({tomorrow.toDateString()})</span>
              </button>
            </div>
          </div>
          <div className="flex flex-col">
            <h5 className="font-josefin font-bold text-sm">Filter</h5>
            <div className="flex gap-1">
              <button
                className={classNames(
                  'flex w-[140px] h-7 flex-col items-center border border-black-1 rounded p-0.5 hover:cursor-pointer',
                  {
                    'bg-awaiting-picking ':
                      selectedFilters.indexOf(FILTERS.AWAIT_PICKING.value) > -1,
                  },
                )}
                onClick={() => toggleFilter(FILTERS.AWAIT_PICKING)}>
                <ArchiveBoxXMarkIcon className="w-3" />
                <span className="font-josefin font-bold text-sm ">Needs packing</span>
              </button>
              <button
                className={classNames(
                  'flex w-[140px] flex-col items-center border border-black-1 rounded p-0.5 h-7 hover:cursor-pointer',
                  {
                    'bg-picked': selectedFilters.indexOf(FILTERS.PACKED.value) > -1,
                  },
                )}
                onClick={() => toggleFilter(FILTERS.PACKED)}>
                <GiftIcon className="w-3" />
                <span className="font-josefin font-bold text-sm">Packed</span>
              </button>
              <select
                className="flex w-25 h-7 flex-col items-center border border-black-1 rounded p-2 hover:cursor-pointer"
                value={selectedTime}
                onChange={e => setSelectedTime(e.target.value)}
                name="time"
                id="time"
                on>
                <option value="">Choose Pickup Time</option>
                <option value="11:00">11:00</option>
                <option value="13:00">13:00</option>
                <option value="15:00">15:00</option>
                <option value="20:00">20:00</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <OrderListCards isLoading={!data} orders={orderList} />
    </Container>
  );
};

export default OrdersPage;
