import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import useGlobalStore from 'store';
import OrderStatus from 'components/orderStatus/OrderStatus';
import {
  PrinterIcon,
  ClipboardDocumentCheckIcon,
  ArchiveBoxXMarkIcon,
} from '@heroicons/react/24/outline';
import api from 'api';
import { ECOM_STATUS } from 'enums';
import PrePackPrint from 'components/prePackPrint/PrePackPrint';

const OrderList = ({ isLoading, orders, showPayed }) => {
  const activeOrder = useGlobalStore(state => state.pos.activeOrder);
  const posActions = useGlobalStore(state => state.posActions);
  const [ordersToShow, setOrdersToShow] = useState(orders);

  useEffect(() => {
    setOrdersToShow(orders);
  }, [orders]);

  const setOrderStatus = async (e, index, status, orderId) => {
    e.stopPropagation();
    const newOrders = [...ordersToShow];
    newOrders[index].handlingStatus.state = status.statusState;
    setOrdersToShow(newOrders);
    await api.setOrderStatus(orderId, status);
    if (activeOrder?.id === orderId) {
      posActions.getActiveOrder(orderId);
    }
  };

  if (isLoading) {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
        <div
          className={classNames(
            'font-bold h-20 flex flex-col flex-wrap w-full border border-black-1 px-2 py-2 bg-transparent drop-shadow-md cursor-pointer opacity-30',
          )}>
          <div className="flex gap-1 mt-auto">
            <button className="flex border rounded p-1 grow items-center border-black-1 hover:bg-black-1 hover:text-white-1">
              <PrinterIcon className="w-3 mr-1" />
            </button>
            <button className="flex border rounded p-1 grow items-center border-black-1 hover:bg-black-1 hover:text-white-1">
              <ClipboardDocumentCheckIcon className="w-3 mr-1" />
            </button>
          </div>
        </div>
        <div
          className={classNames(
            'font-bold h-20 flex flex-col flex-wrap w-full border border-black-1 px-2 py-2 bg-transparent drop-shadow-md cursor-pointer opacity-30',
          )}>
          <div className="flex gap-1 mt-auto">
            <button className="flex border rounded p-1 grow items-center border-black-1 hover:bg-black-1 hover:text-white-1">
              <PrinterIcon className="w-3 mr-1" />
            </button>
            <button className="flex border rounded p-1 grow items-center border-black-1 hover:bg-black-1 hover:text-white-1">
              <ClipboardDocumentCheckIcon className="w-3 mr-1" />
            </button>
          </div>
        </div>
        <div
          className={classNames(
            'font-bold h-20 flex flex-col flex-wrap w-full border border-black-1 px-2 py-2 bg-transparent drop-shadow-md cursor-pointer opacity-30',
          )}>
          <div className="flex gap-1 mt-auto">
            <button className="flex border rounded p-1 grow items-center border-black-1 hover:bg-black-1 hover:text-white-1">
              <PrinterIcon className="w-3 mr-1" />
            </button>
            <button className="flex border rounded p-1 grow items-center border-black-1 hover:bg-black-1 hover:text-white-1">
              <ClipboardDocumentCheckIcon className="w-3 mr-1" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (ordersToShow === null || typeof ordersToShow === 'undefined' || ordersToShow.length < 1)
    return null;

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-2">
      {ordersToShow.map((order, index) => {
        return (
          <div
            onClick={() => posActions.getActiveOrder(order.id)}
            key={index}
            className={classNames(
              'font-bold flex flex-col flex-wrap w-full border px-2 py-2 bg-white-1 drop-shadow-md cursor-pointer ',
              {
                // 'bg-green-1/20': order.handlingStatus.state === ECOM_STATUS.PICKED.statusState,
                'awaiting-picking':
                  order.handlingStatus.state === ECOM_STATUS.AWAITING_PICKING.statusState,
              },
              {
                // 'bg-green-1/20': order.handlingStatus.state === ECOM_STATUS.PICKED.statusState,
                picked: order.handlingStatus.state === ECOM_STATUS.PICKED.statusState,
              },
            )}>
            <div className="flex flex-grow items-center" data-toggle="collapse">
              <h4>{order.date}</h4>
              <h4 className="">{order.orderNumber}</h4>
              <h4 className="ml-auto">
                {new Intl.NumberFormat('en', {
                  style: 'currency',
                  currency: order.currency,
                }).format(order.grandTotal.incVat)}
              </h4>
            </div>
            <div className="flex mt-1 gap-1">
              <OrderStatus state={order.handlingStatus.state} />
              {order.paymentStatus?.state && <OrderStatus state={order.paymentStatus.state} />}
            </div>
            <div className="flex gap-1 mt-2">
              <PrePackPrint order={order}>
                <button
                  className={classNames(
                    'font-josefin flex border border-black-1 rounded p-1 grow items-center hover:bg-black-1 hover:text-white-1',
                    {
                      // 'bg-green-1/20': order.handlingStatus.state === ECOM_STATUS.PICKED.statusState,
                      'bg-white-1':
                        order.handlingStatus.state === ECOM_STATUS.AWAITING_PICKING.statusState,
                    },
                  )}>
                  <PrinterIcon className="w-3 mr-1" />
                  Print
                </button>
              </PrePackPrint>
              {order.handlingStatus.state === ECOM_STATUS.AWAITING_PICKING.statusState && (
                <button
                  onClick={e =>
                    setOrderStatus(
                      e,
                      index,
                      {
                        statusType: ECOM_STATUS.PICKED.statusType,
                        statusState: ECOM_STATUS.PICKED.statusState,
                      },
                      order.id,
                    )
                  }
                  className={classNames(
                    'font-josefin flex border border-black-1 rounded p-1 grow items-center ',
                    {
                      // 'bg-green-1/20': order.handlingStatus.state === ECOM_STATUS.PICKED.statusState,
                      'bg-white-1':
                        order.handlingStatus.state === ECOM_STATUS.AWAITING_PICKING.statusState,
                    },
                  )}>
                  <ClipboardDocumentCheckIcon className="w-3 mr-1" />
                  Mark as Packed
                </button>
              )}

              {order.handlingStatus.state === ECOM_STATUS.PICKED.statusState && (
                <button
                  onClick={e =>
                    setOrderStatus(
                      e,
                      index,
                      {
                        statusType: ECOM_STATUS.AWAITING_PICKING.statusType,
                        statusState: ECOM_STATUS.AWAITING_PICKING.statusState,
                      },
                      order.id,
                    )
                  }
                  className="font-josefin flex border rounded p-1 grow items-center border-black-1 ">
                  <ArchiveBoxXMarkIcon className="w-3 mr-1" />
                  Mark as unpacked
                </button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderList;
