import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import useGbobalStore from 'store';
import { PAYMENT_STATUS } from 'enums';
import { CheckBadgeIcon, XCircleIcon } from '@heroicons/react/24/outline';
const PaymentStatus = ({ className, open = false, status, text }) => {
  const posActions = useGbobalStore(state => state.posActions);
  const [timeLeft, setTimeLeft] = useState(5);
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
    if (open) setTimeLeft(5);
  }, [open]);

  useEffect(() => {
    // exit early when we reach 0
    if (!timeLeft) {
      if (status !== PAYMENT_STATUS.FAILURE.key) posActions.closePaymentStatus();
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, posActions, status]);

  const close = () => {
    posActions.closePaymentStatus();
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black-1 bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white-1 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="font-josefin text-3xl uppercase font-bold leading-6 mb-4 text-center">
                  Payment complete
                </Dialog.Title>

                {status === PAYMENT_STATUS.FAILURE.key ? (
                  <div className={`${className} flex flex-col items-center justify-center`}>
                    <XCircleIcon className="w-40 h-40 text-red-1" />
                    <div className=" text-black-1 font-josefin text-3xl mt-2">
                      {PAYMENT_STATUS.FAILURE.text}
                    </div>
                  </div>
                ) : (
                  <div className={`${className} flex flex-col items-center justify-center`}>
                    <CheckBadgeIcon className="w-40 h-40 text-green-1" />
                    <div className=" text-black-1 font-josefin text-3xl mt-2">{text}</div>
                  </div>
                )}

                <div className="mt-6 flex justify-center items-center">
                  <button
                    type="button"
                    className="px-4 py-2 font-semibold text-lg border-2 border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black rounded-md shadow-sm hover:scale-105 ease-out duration-300"
                    onClick={() => close()}>
                    Close {status !== PAYMENT_STATUS.FAILURE.key && <>{timeLeft}s</>}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );

  /* 
	if(TERMINAL_STATUS.AWAITING_PIN) 
	return (
		<div className={`${className} flex items-center justify-center`}>
			<div className={classNames(styles.spinningBorder, "animate-spin-slow inline-block w-full h-full border-8 rounded-full text-brand-1 bg-gray-1 bg-brand-1/30")} role="status" />			
			<div className="absolute text-white-1 font-josefin text-3xl">{text}</div>
	</div>	)
	
	if(typeof text !== "undefined") 
	return (
		<div className={`${className} flex items-center justify-center`}>
			<div className={classNames(styles.spinningBorder, "animate-spin-slow inline-block w-full h-full border-8 rounded-full text-brand-1 bg-gray-1 bg-brand-1/30")} role="status" />			
			<div className="absolute text-white-1 font-josefin text-3xl">{text}</div>
	</div>	)

  return null */
};

export default PaymentStatus;
