import React from 'react';
import {
  ArchiveBoxXMarkIcon,
  CreditCardIcon,
  BanknotesIcon,
  GiftIcon,
  PaperAirplaneIcon,
  ReceiptRefundIcon,
} from '@heroicons/react/24/outline';
import { ECOM_STATUS } from 'enums';
import { isRefunded } from 'lib/ecomHelper';

const OrderStatus = ({ state, date }) => {
  return (
    <>
      {(state === ECOM_STATUS.AWAITING_PICKING.statusState || state === undefined) && (
        <div className="flex flex-col items-center">
          <ArchiveBoxXMarkIcon className="w-3" />
          <span className="font-josefin font-bold  text-sm">Not Packed</span>
        </div>
      )}
      {state === ECOM_STATUS.PICKED.statusState && (
        <div className="flex flex-col items-center">
          <GiftIcon className="w-3" />
          <span className="font-josefin font-bold text-sm">Packed</span>
        </div>
      )}
      {(state === ECOM_STATUS.RESERVED.statusState ||
        state === ECOM_STATUS.CAPTURED.statusState ||
        state === ECOM_STATUS.CAPTUREDCREATED.statusState) && (
        <div className="flex flex-col items-center">
          <CreditCardIcon className="w-3" />
          <span className="font-josefin font-bold  text-sm">Payed</span>
        </div>
      )}
      {(state === ECOM_STATUS.INSTORE.statusState ||
        state === ECOM_STATUS.AUTHORIZATIONFAILED.statusState ||
        state === ECOM_STATUS.CREATED.statusState) && (
        <div className="flex flex-col items-center">
          <BanknotesIcon className="w-3" />
          <span className="font-josefin font-bold  text-sm">Not payed</span>
        </div>
      )}
      {state === ECOM_STATUS.DELIVERED.statusState && (
        <div className="flex flex-col items-center">
          <PaperAirplaneIcon className="w-3" />
          <span className="font-josefin font-bold  text-sm">Delivered</span>
        </div>
      )}
      {isRefunded(state) && (
        <div className="flex flex-col items-center">
          <ReceiptRefundIcon className="w-3" />
          <span className="font-josefin font-bold  text-sm">Refunded</span>
        </div>
      )}
    </>
  );
};

export default OrderStatus;
