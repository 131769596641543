import { useState } from 'react';
import useGlobalStore from 'store';
// import { Listbox, Transition } from '@headlessui/react';
// import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

// const people = [
//   { id: 1, name: 'Durward Reynolds', unavailable: false },
//   { id: 2, name: 'Kenton Towne', unavailable: false },
//   { id: 3, name: 'Therese Wunsch', unavailable: false },
//   { id: 4, name: 'Benedict Kessler', unavailable: true },
//   { id: 5, name: 'Katelyn Rohan', unavailable: false },
// ];

const Login = ({ children }) => {
  const authActions = useGlobalStore(state => state.authActions);
  const errorMessage = useGlobalStore(state => state.auth.errorMessage);

  // const [selected, setSelected] = useState(people[0]);

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  const { signIn } = authActions;

  const handleChange = e => {
    const newData = { ...formData };
    newData[e.target.name] = e.target.value;
    setFormData(newData);
  };

  const handleSignIn = e => {
    e.preventDefault();
    signIn(formData);
  };

  return (
    <form onSubmit={e => handleSignIn(e)}>
      <h4 className="font-josefin text-4xl ">Login</h4>
      {errorMessage === 'Login Failed' && <p className="text-red-1 mb-1"> Login failed</p>}
      <input
        name="username"
        value={formData.username}
        onChange={e => handleChange(e)}
        placeholder="Username"
        className="w-full text-lato text-lg p-1 mb-1 bg-white-1 border-1 border border-black-1/60 rounded bg-white ring-1 ring-black-1/5"
        type="text"
      />
      <input
        name="password"
        value={formData.password}
        onChange={e => handleChange(e)}
        placeholder="Password"
        className="w-full text-lato text-lg p-1 mb-1 bg-white-1 border-1 border border-black-1/60 rounded bg-white focus:shadow-md ring-1 ring-black-1/5"
        type="password"
      />
      {/* <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded bg-white p-1 text-left text-lato text-lg shadow-md"> */}
      {/* <Listbox.Button className="relative w-full cursor-default rounded bg-white p-1 text-left text-lato text-lg shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"> */}
      {/* <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-3 w-3 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {people.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={person}>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {person.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox> */}

      <button className="px-3 py-1 font-semibold text-lg border-2 border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black rounded-md shadow-sm hover:scale-105 ease-out duration-300">
        Login
      </button>
    </form>
  );
};

export default Login;
