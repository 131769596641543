// import queryString from 'query-string';
// https://www.npmjs.com/package/axios-cache-adapter
import axios from 'axios';
import mockApi from './mock';
// import { CART_TYPES } from '../enums';

const logError = err => {
  console.log(err.response.status);
};

const authHeader = () => {
  let jwtStr = localStorage.getItem('JWT');

  if (jwtStr !== null) {
    const jwt = JSON.parse(jwtStr);
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${jwt.accessToken}`,
    };
  } else {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }
};

const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const apiChannelId = "channelID"
const instance = axios.create({
  // withCredentials: true,
  headers: authHeader(),
});

export const fetcher = url => instance.get(url).then(res => res.data);

const getCart = async marketId => {
  return instance.get(`${baseUrl}/v1/carts`).catch(err => {
    logError(err);
    return err;
  });
};

const updateCart = async (marketId, cartArticle) => {
  return instance.patch(`${baseUrl}/v1/carts`, cartArticle).catch(err => {
    logError(err);
    return err;
  });
};

const removeCartItem = async (cartId, sku) => {
  return instance
    .delete(`${baseUrl}/v1/Cart/${cartId}/deleteitem`, {
      data: {
        sku: sku,
      },
    })
    .catch(err => {
      logError(err);
      return err;
    });
};

const initiatePayment = async orderGuid => {
  return instance.post(`${baseUrl}/v1/pos/pay/${orderGuid}`).catch(err => {
    logError(err);
    return err;
  });
};

const getOrders = async () => {
  return instance.get(`${baseUrl}/v1/orders`).catch(err => {
    logError(err);
    return err;
  });
};

const getOrder = async orderNumber => {
  return instance.get(`${baseUrl}/v1/orders/${orderNumber}`).catch(err => {
    logError(err);
    return err;
  });
};

const setOrderStatus = async (orderId, status) => {
  return instance.post(`${baseUrl}/v1/order/status/${orderId}`, status).catch(err => {
    logError(err);
    return err;
  });
};

const setOrderAsDelivered = async orderId => {
  return instance.post(`${baseUrl}/v1/order/status/delivered/${orderId}`).catch(err => {
    logError(err);
    return err;
  });
};

const cancelPayment = async serviceId => {
  return instance.post(`${baseUrl}/v1/pos/abort/${serviceId}`).catch(err => {
    logError(err);
    return err;
  });
};

const reversePayment = async orderId => {
  return instance.post(`${baseUrl}/v1/pos/reversal/${orderId}`).catch(err => {
    logError(err);
    return err;
  });
};

const signIn = async (username, password) => {
  const user = {
    username: username,
    password: password,
    channel: '7543DDB7-93C1-4042-AFD2-D102A970F79E',
    market: 'en-GB',
    posId: 'CY-HH-POS1',
  };

  return instance
    .post(`${baseUrl}/auth/login`, user)
    .then(response => {
      const { data } = response;
      localStorage.setItem('JWT', JSON.stringify(data.payload));
      instance.defaults.headers = authHeader();

      return response;
    })
    .catch(err => {
      logError(err);
      return err;
    });
};

const signOut = async () => {
  try {
    localStorage.removeItem('JWT');
    return true;
  } catch (err) {
    return false;
  }
};

const getUser = async () => {
  return instance.get(`${baseUrl}/auth/user`).catch(err => {
    logError(err);
    return err;
  });
};

let api = {};

if (
  typeof process.env.REACT_APP_USE_MOCKDATA === 'undefined' ||
  process.env.REACT_APP_USE_MOCKDATA === 'false'
) {
  api = {
    getCart,
    updateCart,
    removeCartItem,
    initiatePayment,
    getOrders,
    getOrder,
    signIn,
    signOut,
    setOrderStatus,
    getUser,
    cancelPayment,
    setOrderAsDelivered,
    reversePayment,
  };
} else {
  api = mockApi;
}
export { api as default };
