import classNames from 'classnames';
import styles from './List.module.scss';

const List = ({ items }) => {
  return (
    <ul className="bg-gray-4 py-1 m-0 font-audiowide">
      {items.map((item) => {
        if(item === null) return null
        const {
          text,
          as,
          className: classNameProp,
          isSelected,
          ...props
        } = item;

        const className = classNames(
          styles.ListItemContent,
          'text-left block p-1 px-2 text-white-1 hover:text-white-1',
          {
            [styles.isSelectedDecorator]: item.isSelected && as !== 'a',
          },
          classNameProp
        );

        return (
          <li
            key={item.text}
            {...props}
            className={classNames(
              styles.ListItem,
              'hover:bg-gray-3 shadow-lg cursor-pointer uppercase',
              {
                [styles.isSelected]: item.isSelected,
              }
            )}
          >
            {as === 'a' && (
              <a {...props} className={className}>
                <span className={styles.isSelectedDecorator}>{text}</span>
              </a>
            )}
            {as === 'button' && (
              <button {...props} className={className}>
                {text}
              </button>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default List;
