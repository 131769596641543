import React, { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
const ConfirmationDialog = ({
  className,
  open = false,
  confirmCallback,
  cancelCallback,
  header,
  text,
  okText,
  cancelText,
}) => {
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black-1 bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white-1 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="font-josefin text-3xl uppercase font-bold leading-6 mb-4 text-center">
                  {header}
                </Dialog.Title>
                <div className="font-josefin text-lg">{text}</div>
                <div className="flex justify-end mt-4">
                  <button
                    onClick={confirmCallback}
                    className="px-2 py-2 mr-1 flex items-center font-josefin font-semibold text-lg border-2 rounded-md shadow-sm hover:scale-105 ease-out duration-300 border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black">
                    {okText}
                  </button>
                  <button
                    onClick={cancelCallback}
                    className="px-2 py-2 flex items-center font-josefin font-semibold text-lg border-2 rounded-md shadow-sm hover:scale-105 ease-out duration-300 border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black">
                    {cancelText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );

  /* 
	if(TERMINAL_STATUS.AWAITING_PIN) 
	return (
		<div className={`${className} flex items-center justify-center`}>
			<div className={classNames(styles.spinningBorder, "animate-spin-slow inline-block w-full h-full border-8 rounded-full text-brand-1 bg-gray-1 bg-brand-1/30")} role="status" />			
			<div className="absolute text-white-1 font-josefin text-3xl">{text}</div>
	</div>	)
	
	if(typeof text !== "undefined") 
	return (
		<div className={`${className} flex items-center justify-center`}>
			<div className={classNames(styles.spinningBorder, "animate-spin-slow inline-block w-full h-full border-8 rounded-full text-brand-1 bg-gray-1 bg-brand-1/30")} role="status" />			
			<div className="absolute text-white-1 font-josefin text-3xl">{text}</div>
	</div>	)

  return null */
};

export default ConfirmationDialog;
