import HomePage from './../pages/HomePage';
import OrdersPage from './../pages/OrdersPage';
import ReceiptsPage from './../pages/Receipts';
import TestPage from './../pages/TestPage';

const DefaultRoutes = [
  {
    index: 1,
    component: <OrdersPage />,
    text: 'Orders',
    href: '/orders',
    as: 'a',
    icon: <i className="fa-solid fa-bars-staggered"></i>,
    showInMenu: true,
    //isSelected: pathname === '/orders',
  },
  {
    index: 2,
    component: <ReceiptsPage />,
    text: 'Receipts',
    href: '/receipts',
    as: 'a',
    icon: <i className="fa-solid fa-receipt"></i>,
    showInMenu: true,
    //isSelected: pathname === '/orders',
  },
  {
    index: 0,
    component: <TestPage />,
    text: 'Test Page',
    href: '/test',
    as: 'a',
    icon: <i className="fa-solid fa-receipt"></i>,
    showInMenu: false,
    //isSelected: pathname === '/orders',
  },
  {
    index: 0,
    component: <HomePage />,
    text: 'Home',
    href: '/',
    as: 'a',
    showInMenu: true,
    //isSelected: pathname === '/',
  },
];

export default DefaultRoutes;
