import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
const PrePackPrint = ({ order, children }) => {
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printOrder = async (e, order) => {
    e.stopPropagation();
    handlePrint();
  };

  return (
    <>
      {React.cloneElement(children, { onClick: e => printOrder(e, order) })}
      <div style={{ display: 'none' }}>
        <OrderPrint order={order} ref={componentRef} />
      </div>
    </>
  );
};

const OrderPrint = React.forwardRef(({ order }, ref) => (
  <div ref={ref}>
    <div className={`w-60 flex flex-col mx-auto`}>
      <div className="flex ">
        <h2 className="px-2 font-josefin text-4xl my-2">Order - {order.orderNumber}</h2>
        <h2 className="px-2 font-josefin text-4xl my-2">Reg Number - {order.regNumber}</h2>
      </div>
      <h3 className="px-2 mb-2 font-josefin text-2xl">Order details</h3>
      <div className="px-2 flex flex-wrap">
        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin font-bold">Order number</h5>
          <div>{order.orderNumber}</div>
        </div>
        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin font-bold">Pickup date</h5>
          <div>{`${order.pickupDate ? new Date(order.pickupDate).toDateString() : '-'}`}</div>
        </div>
        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin font-bold">Pickup time</h5>
          <div>{`${
            order.pickupDate
              ? new Date(order.pickupDate).toLocaleTimeString('en-GB', {
                  hour: '2-digit',
                  minute: '2-digit',
                })
              : '-'
          }`}</div>
        </div>
        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin font-bold">Name</h5>
          <div>{order.address.name}</div>
        </div>

        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin font-bold">Phone</h5>
          <div>{`${order.address.phone ? order.address.phone : '-'}`}</div>
        </div>
        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin w-1/2 font-bold">Email</h5>
          <div>{`${order.address.email ? order.address.email : '-'}`}</div>
        </div>
        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin font-bold">Booking reference</h5>
          <div>{`${order.sloopNumber ? order.sloopNumber : '-'}`}</div>
        </div>
        <div className="w-1/2 basis-1/2 mb-1">
          <h5 className="font-josefin w-1/2 font-bold">Reg Number</h5>
          <div>{`${order.regNumber ? order.regNumber : '-'}`}</div>
        </div>
      </div>
      <h3 className="px-2 mb-1 font-josefin text-2xl">Articles</h3>
      <div className="px-2 flex-grow overflow-auto">
        <div className="flex flex-col">
          {order.articles.map((article, key) => {
            return (
              <div key={key} className=" border-b border-gray-2 last:border-b-0">
                <div className="text-sm">
                  {article.quantity} x {article.name}
                </div>
                <div className="flex justify-between">
                  <span className="italic text-sm">
                    (
                    {new Intl.NumberFormat('en', {
                      style: 'currency',
                      currency: order.currency,
                    }).format(article.priceUnit.incVat)}
                    )
                  </span>
                  <span className="font-bold">
                    {new Intl.NumberFormat('en', {
                      style: 'currency',
                      currency: order.currency,
                    }).format(article.priceTotal.incVat)}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="h-30 px-2 pt-2 border-t border-gray-2">
        <div className="font-bold text-lg flex justify-between mb-2">
          <span>Grand total:</span>
          <span>
            {new Intl.NumberFormat('en', {
              style: 'currency',
              currency: order.currency,
            }).format(order.grandTotal.incVat)}
          </span>
        </div>
      </div>
    </div>
  </div>
));

export default PrePackPrint;
