import React from 'react';
import Container from '../components/container/Container';
// import TerminalStatus from 'components/terminalStatus/TerminalStatus';
// import ServerMessageProvider from 'components/serverMessageProvider/ServerMessageProvider';
// import { TERMINAL_STATUS } from 'enums';

const HomePage = props => {
  return (
    <Container>
      {/* <TerminalStatus open={true} status={TERMINAL_STATUS.AWAITING_PIN} text="asd" /> */}
      {/* <ServerMessageProvider></ServerMessageProvider> */}
    </Container>
  );
};

export default HomePage;
