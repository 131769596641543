import React, { Fragment, useState, useEffect } from 'react';
import styles from './TerminalStatus.module.scss';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import useGbobalStore from 'store';
import { PAYMENT_STATUS } from 'enums';
import { XCircleIcon } from '@heroicons/react/24/outline';
const TerminalStatus = ({ className, open = false, status, text }) => {
  const posActions = useGbobalStore(state => state.posActions);
  const serviceId = useGbobalStore(state => state.pos.payment.serviceId);
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black-1 bg-opacity-40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-xl bg-white-1 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="font-josefin text-3xl uppercase font-bold leading-6 mb-4 text-center">
                  Card payment
                </Dialog.Title>

                {status === PAYMENT_STATUS.FAILURE.key ? (
                  <div className={`${className} flex flex-col items-center justify-center`}>
                    <XCircleIcon className="w-10 h-10 text-red-1" />
                    <div className=" text-black-1 font-josefin text-3xl mt-2">
                      {PAYMENT_STATUS.FAILURE.text}
                    </div>
                  </div>
                ) : (
                  <div className={`${className} flex flex-col items-center justify-center`}>
                    <div
                      className={classNames(
                        styles.spinningBorder,
                        'animate-spin-slow inline-block w-10 h-10 border-4 rounded-full text-brand-1 ',
                      )}
                      role="status"
                    />
                    <div className=" text-black-1 font-josefin text-3xl mt-2">{text}</div>
                  </div>
                )}

                <div className="mt-6 flex justify-center items-center">
                  {status === PAYMENT_STATUS.FAILURE.key && (
                    <button
                      type="button"
                      className="px-4 py-2 font-semibold text-lg border-2 border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black rounded-md shadow-sm hover:scale-105 ease-out duration-300"
                      onClick={() => posActions.cancelPayment(serviceId)}>
                      Close
                    </button>
                  )}
                  {status !== 'Faliure' && (
                    <button
                      type="button"
                      className="px-4 py-2 font-semibold text-lg border-2 border-black-1 bg-white-1 hover:bg-black-1 hover:text-white-1 text-black rounded-md shadow-sm hover:scale-105 ease-out duration-300"
                      onClick={() => posActions.cancelPayment(serviceId)}>
                      Cancel payment
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );

  /* 
	if(TERMINAL_STATUS.AWAITING_PIN) 
	return (
		<div className={`${className} flex items-center justify-center`}>
			<div className={classNames(styles.spinningBorder, "animate-spin-slow inline-block w-full h-full border-8 rounded-full text-brand-1 bg-gray-1 bg-brand-1/30")} role="status" />			
			<div className="absolute text-white-1 font-josefin text-3xl">{text}</div>
	</div>	)
	
	if(typeof text !== "undefined") 
	return (
		<div className={`${className} flex items-center justify-center`}>
			<div className={classNames(styles.spinningBorder, "animate-spin-slow inline-block w-full h-full border-8 rounded-full text-brand-1 bg-gray-1 bg-brand-1/30")} role="status" />			
			<div className="absolute text-white-1 font-josefin text-3xl">{text}</div>
	</div>	)

  return null */
};

export default TerminalStatus;
