import React, { useEffect } from 'react';
import useGlobalStore from 'store';

import Login from './Login';
const AuthWrapper = ({ children, requireLogin }) => {
  const status = useGlobalStore(state => state.auth.status);
  const { getCurrentUser } = useGlobalStore(state => state.authActions);

  useEffect(() => {
    if (status === 'none') getCurrentUser();
  }, [status, getCurrentUser, requireLogin]);

  if (status === 'in') return <>{children}</>;
  if (status === 'none') return <></>;

  return (
    <div>
      <div className="w-screen h-screen bg-black-1/50 flex items-center justify-center">
        <div className="w-40 border-2 border-brand-2 rounded p-2 bg-white-1">
          <Login />
        </div>
      </div>
    </div>
  );
};

export default AuthWrapper;
